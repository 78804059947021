@media print {
  body {
    font-size: 0.7rem !important;
  }
  #dark-mode-container {
    display: none;
  }
  h1 {
    font-size: 3.4rem !important;
  }
  h2 {
    font-size: 2.2rem !important;
  }
  h3 {
    font-size: 1.7rem !important;
  }
  h4 {
    font-size: 1.2rem !important;
    small {
      font-size: 0.7em !important;
    }
  }
  h5 {
    font-size: 1.1rem !important;
  }
  h6 {
    font-size: 1rem !important;
  }
  blockquote,
  pre {
    border: none !important;
  }
  a:not(.btn) {
    text-decoration: underline;
  }
  pre {
    white-space: pre-wrap !important;
  }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }
  img {
    page-break-inside: avoid;
  }
  h1,
  h2,
  h3 {
    page-break-after: avoid;
  }
  @page {
    size: a4;
  }
  body {
    min-width: 992px !important;
  }
  .container {
    min-width: 992px !important;
  }
  .badge {
    border: 1px solid #000;
    color: #000 !important;
  }
}
