#experience {
  h4 {
    font-weight: 600;
    small {
      font-weight: 400;
    }
  }

  .highlights {
    ul {
      columns: 2;
      li {
        break-inside: avoid-column;
      }
    }
  }
}
