$font-family-sans-serif: "Barlow";

$link-color: rgb(161, 12, 12);
$link-color-dark: rgb(217, 54, 54);

$link-hover-color: lighten($link-color, 10);
$link-hover-color-dark: lighten($link-color-dark, 10);

$grid-gutter-width: 2em;

$small-font-size: 0.75em;

$font-size-base: 0.9rem;
