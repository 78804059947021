#basics {
  h1 {
    font-size: 5rem;
    margin: 0;
    font-weight: 800;
  }
  img {
    filter: grayscale(100%);
  }
}
