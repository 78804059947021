@use "~@fontsource/barlow/scss/mixins" as Barlow;

@include Barlow.faces(
  $weights: (
    200,
    400,
    600,
    800,
  )
);

.text-prewrap {
  white-space: pre-wrap;
}

.text-justify {
  text-align: justify;
}

h2.section-title {
  font-weight: 800;
  font-size: 3rem;
}

@include media-breakpoint-down(lg) {
  h2.section-title {
    font-size: 1.9rem;
  }
}

a {
  text-decoration-style: dotted;
}

hr {
  border-top: 1px dotted $black !important;
}

ul.list-condensed {
  font-size: 1em;
  line-height: 1.4em;
}

p {
  margin-bottom: 0.5rem;
}
