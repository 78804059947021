body {
  transition: background 500ms;
}

body {
  --color-text: #777;
  --color-text-active: #7489ff;
  --color-arrow: #88888852;
}

body[data-bs-theme="dark"] {
  --color-text: #88888895;
  --color-text-active: #7489ff;
  --color-arrow: #656565;
}

#dark-mode {
  transition: background-color 400ms cubic-bezier(0.995, -0.265, 0.855, 0.505);
  span {
    cursor: pointer;
    padding: 10px 0;
  }

  transition:
    background-color 400ms cubic-bezier(0.995, -0.265, 0.855, 0.505),
    box-shadow 100ms linear;

  #dark-mode-arrow {
    margin: 0px 20px;
    color: var(--color-arrow);
    transition: all 400ms cubic-bezier(0.995, -0.265, 0.855, 0.505);
  }

  i {
    transition: all 400ms cubic-bezier(0.995, -0.265, 0.855, 0.505);
  }
}

body[data-bs-theme="dark"] #dark-mode {
  #dark-mode-arrow {
    transform: rotate(-180deg);
  }

  #dark-mode-moon {
    color: $link-color;
  }
}

body:not([data-bs-theme]) #dark-mode {
  #dark-mode-sun {
    color: $link-color;
  }
}
